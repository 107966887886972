<template>
    <div class="pageBox">
        <div class="bannerBox"></div>
        <div class="topBox">
            <div class="title">
                <img src="../../assets/loginout/title-tips.png"/>
                <div class="titleCenter"> 材质 </div>
                <img src="../../assets/loginout/title-tips.png"/>
            </div>
            <div class="typeBox">
                <div class="typeList">
                   <div 
                   @click="textureChange(item.id)"
                   :class="texture == item.id?'Item ItemActive':'Item'" 
                   v-for="(item, index) in textures" 
                   :key="index">
                   {{ item.name }}
                    </div> 
                </div>
            </div>
            <div class="typeContain">
                <div v-for="(item, index) in textureList" :key="index">
                    <div v-if="index==0" class="firstBox" @click="goEntery(item.id)">
                        <el-carousel height="300px">
                            <el-carousel-item :key="index">
                                <img :src="item.cover" style="width: 100%;height: 100%;object-fit: cover;"/>
                            </el-carousel-item>
                        </el-carousel>
                        <div class="TCF-title">
                            <div class="TCF-left">
                                <div>
                                   {{moment(moment(item.createtime).valueOf()).format('YYYY')}}<br/>
                                   {{moment(moment(item.createtime).valueOf()).format('MM-DD')}}
                                </div>
                            </div>
                            <div class="TCF-right">
                                {{ item.title }}
                            </div>
                        </div>
                    </div>
                    <div v-else class="TC-box" @click="goEntery(item.id)">
                        <div class="TC-title">
                            {{ item.title }}
                        </div>
                        <div class="msgBox">
                            <div>创建者：{{ item.provider }}</div>   
                            <div>来源：{{ item.sourcename }}</div>
                        </div>
                        <div class="textBox">
                            {{ item.summary }}
                        </div>
                        <div class="timeBox">
                            {{ item.createtime }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="btnMore">
                <el-button size="large" type="primary" plain @click="openMore(4)">查看更多</el-button>
            </div>
        </div>
        <div class="topBox">
            <div class="title">
                <img src="../../assets/loginout/title-tips.png"/>
                <div class="titleCenter"> 区域 </div>
                <img src="../../assets/loginout/title-tips.png"/>
            </div>
            <div class="typeBox">
                <div class="typeLeft" @click="this.$refs.carousel.prev()">
                    <svg-icon icon-class="arrowLeft" />
                </div>
                <div class="typeCenter">
                    <el-carousel 
                    ref="carousel"
                    indicator-position="none"
                    :autoplay="false" 
                    arrow="never">
                        <el-carousel-item v-for="(items, index) in areas" :key="index">
                            <div class="typeList">
                                <div 
                                @click="areaChange(item.id)"
                                :class="area == item.id?'Item ItemActive':'Item'" 
                                v-for="(item, index) in items" 
                                :key="index">
                                {{ item.name }}
                                </div> 
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                </div>
                <div class="typeLeft" @click="this.$refs.carousel.next()">
                    <svg-icon icon-class="arrowRight" />
                </div>
            </div>
            <div class="typeContain areaContain">
                <div class="TCa-box" @click="goEntery(item.id)"  v-for="(item, index) in  areaList" :key="index">
                    <div class="IconBox">
                        <img :src="item.cover" style="width: 100%; height:100%; object-fit: cover;"/>
                    </div>
                    <div class="TCa-title">
                        {{ item.title }}
                    </div>
                </div>
            </div>
            <div class="btnMore">
                <el-button size="large" type="primary" plain @click="openMore(3)">查看更多</el-button>
            </div>
        </div>

        <div class="topBox">
            <div class="title">
                <img src="../../assets/loginout/title-tips.png"/>
                <div class="titleCenter"> 保存环境 </div>
                <img src="../../assets/loginout/title-tips.png"/>
            </div>
            <div class="typeContain PosiContain">
                <div class="TCp-box" v-for="(item, index) in environment" :key="index">
                   <div class="TCp-title">
                    <div class="TCp-left">{{ item.name }}</div>
                    <div class="TCp-right" @click="openMore(5)">更多 》</div>
                   </div>
                   <div class="ImgBox"><img :src="item.src" style="width: 100%; height: 100%;object-fit: cover;"/></div>
                   <div class="itemBox" @click="goEntery(el.id)"  v-for="(el, index) in item.list" :key="index">
                    <div class="itemName"> ■ {{ el.title }}</div>
                    <div class="itemTime">{{ el.createtime }}</div>
                   </div>
                </div>
            </div>
        </div>

        <div class="topBox">
            <div class="title">
                <img src="../../assets/loginout/title-tips.png"/>
                <div class="titleCenter"> 现有案例 </div>
                <img src="../../assets/loginout/title-tips.png"/>
            </div>
            <div class="typeContain exampContain">
                <div class="TCe-box"  @click="goEntery(item.id)"  v-for="(item, index) in examples" :key="index">
                   <div class="TCe-Title">
                    <div class="titleBox"> {{ item.title }} </div>
                    <div class="titleBottom"></div>
                   </div>
                   <div class="ImgBox"> 
                    <img :src="item.cover" style="width: 100%;height: 100%;object-fit: cover;"/>
                   </div>
                   <div class="msgBox">
                        {{ item.summary }}
                   </div>
                </div>
            </div>
            <div class="btnMore">
                <el-button size="large" type="primary" plain @click="openMore(6)">查看更多</el-button>
            </div>
        </div>
    </div>
</template>
<script>
import { OH_byType, OH_typeList } from '@/api/openHome.js'
import moment  from 'moment'
export default{
    data(){
        return{
            textures:[],
            texture:null,
            textureList:[],
            areas:[],
            area:null,
            areaList:[],
            environment:[
                {name:'展厅',src:require('../../assets/loginout/ev1.jpg'),list:[],id:49},
                {name:'库房',src:require('../../assets/loginout/ev2.jpg'),list:[],id:50},
                {name:'运输',src:require('../../assets/loginout/ev3.jpg'),list:[],id:51}
            ],
            examples:[],
        }
    },
    mounted(){
        this.GetohType()
    },
    methods:{
        moment,
        GetohType(){
            OH_typeList(4).then(r=>{ //材质
                this.textures = r.data
                this.textureChange(this.textures[0].id)
            })
            OH_typeList(3).then(r=>{ //区域
                this.areas = r.data
                this.areas = this.splitArray(r.data, 8)
                this.areaChange(r.data[0].id)
            })
            OH_byType({page:1,size:5,types:49}).then(r=>{
                this.environment[0].list = r.data.list    
            })
            OH_byType({page:1,size:5,types:50}).then(r=>{
                this.environment[1].list = r.data.list    
            })
            OH_byType({page:1,size:5,types:51}).then(r=>{
                this.environment[2].list = r.data.list    
            })
            OH_byType({page:1,size:3,types:6}).then(r=>{
                this.examples = r.data.list  
            })
        },
        splitArray(array, size){
            let data = [];
            for (let i = 0; i < array.length; i += size) {
                data.push(array.slice(i, i + size))
            }
            return data
        },
        textureChange(id){
            this.texture = id
            let query = {
                page:1,
                size:4,
                types:this.texture
            }
            OH_byType(query).then(r=>{
                this.textureList = r.data.list    
            })
        },
        areaChange(id){
            this.area = id
            let query = {
                page:1,
                size:6,
                types:this.area
            }
            OH_byType(query).then(r=>{
                this.areaList = r.data.list    
            })
        },
        goEntery(id){
            this.$router.push({
                query:{'id':id},
                path:'/openDetils'
            })
        },
        openMore(type){
            this.$router.push({
                query:{'type':type},
                path:'/openMoreFile2'
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.pageBox{
    width:100%;
    padding:20px 0px;
    background-color:white;
}
.bannerBox{
    width:100%;
    height:280px;
    background-image: url('../../assets/loginout/案例库.jpg');
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
.topBox{
    width:1400px;
    margin: 0 auto;
    .title{
        display:flex;
        justify-content:center;
        align-items:center;
        height:100px;
        margin-top: 80px;
        color:rgb(200, 195, 195);
        .titleCenter{
            margin:0px 20px;
            font-size:20px;
            color:#333;
            font-weight: 600;
        }
    }
    .typeBox{
        width:100%;
        display:flex;
        justify-content:space-around;
        align-items:center;
        .typeCenter{
            width:800px;
            height:50px
        }
        .typeLeft{
            width:30px;
            height:30px;
            background-color:blue;
            border-radius:50%;
            color:white;
            line-height:30px;
            text-align:center;
            cursor:pointer;
        }
        .typeList{
            width:800px;
            height:50px;
            display:flex;
            overflow: hidden;
            justify-content:space-around;
            align-items:center; 
            .Item{
                padding:5px 20px;
                text-align:center;
                background-color: rgb(227, 227, 227);
                border-radius: 10px;
                font-size: 14px;
                cursor: pointer;
            }
            .ItemActive{
                background-color: rgb(0, 106, 255);
                color: white;
            }
        }
    }
    .typeContain{
        width: 100%;
        margin-top: 20px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        .firstBox{
            width: 400px;
            height: 100%;
            cursor: pointer;
            background-color: rgb(234, 234, 234);
        }
        .TC-box{
            cursor: pointer;
            width: 300px;
            height: 100%;
            background-color: rgb(234, 234, 234);
            padding: 20px;
            box-sizing: border-box;
            .msgBox{
                display: flex;
                justify-content: space-between;
                align-items: center;
                color: #666;
                font-size: 14px;
                margin-top: 20px;
                border-bottom: 1px solid #aaa9a9;
                height: 40px;
                
            }
            .TC-title{
                font-weight: 600;
                margin-top: 30px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            .textBox{
                width: 100%;
                font-size: 14px;
                margin-top: 40px;
                display: -webkit-box;
                overflow: hidden;
                -webkit-box-orient: vertical;
                text-align: justify;
                -webkit-line-clamp: 4;
            }
            .timeBox{
                display: flex;
                justify-content: flex-end;
                align-items: flex-end;
                height: 100px;
                width: 100%;
            }
        }
        .TCF-title{
            width: 100%;
            height: 89px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .TCF-left{
                width: 80px;
                height: 100%;
                background-color: rgb(0, 81, 255);
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                color: white;
            }
            .TCF-right{
                width: calc(100% - 80px);
                height: 100%;
                padding: 10px;
                box-sizing: border-box;
                font-weight: 600;
                display: flex;
                justify-content: flex-start;
                align-items: center;
            }
        }
    }
    .areaContain{
        height: 340px;
        .TCa-box{
            cursor: pointer;
            width: 210px;
            height: 100%;
            background-color: rgb(234, 234, 234);
        }
        .IconBox{
            width: 100%;
            height: calc(100% - 50px);
            background-color: blue;
        }
        .TCa-title{
            height: 50px;
            padding: 0 10px;
            box-sizing: border-box;
            line-height: 50px;
            text-align: center;
            font-weight: 600;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

        }

    }
    
}
.PosiContain{
    .TCp-box{
        width: 450px;
    }
    .TCp-title{
        width: 100%;
        height: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        .TCp-left{
            width: 50px;
            height: 100%;
            display:flex;
            justify-content:flex-start;
            align-items: center;
            border-bottom: 5px solid blue;
            box-sizing: border-box;
            font-weight: 600;
        }
        .TCp-right{
            width: calc(100% - 50px);
            height: 100%;
            font-size: 14px;
            cursor: pointer;
            display:flex;
            justify-content:flex-end;
            align-items: center;
            border-bottom: 1px solid rgb(218, 218, 218);
            box-sizing: border-box;
            color: #666;
        }
    }
    .ImgBox{
        width: 100%;
        background-color: blue;
        height: 260px;
    }
    .itemBox{
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        border-bottom: 1px solid rgb(218, 218, 218);
        box-sizing: border-box;
        .itemName{
            width: calc(100% - 110px);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .itemTime{
            width: 100px;
            color: #666;
            font-size: 14px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
}
.exampContain{
    .TCe-box{
        width: 450px;
        cursor: pointer;
    }
    .TCe-Title{
        width: 100%;
        height: 50px;
        position: relative;
        display: flex;
        justify-content: flex-start;
        .titleBottom{
            position: absolute;
            bottom: 0px;
            left: 0px;
            height: 10px;
            width: 100%;
            background-color: rgb(12, 134, 234);
            border-radius: 0px 10px 0px 0px ;
        }
        .titleBox{
            padding: 0px 50px 0px 10px;
            line-height: 50px;
            max-width: 100%;
            font-size: 16px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-weight: 600;
            height: 50px;
            border-radius: 0px 50px 0px 0px;
            background-color: rgb(12, 134, 234);
            color: white;
            box-sizing: border-box;
        }
    }
    .ImgBox{
        padding: 20px;
        width: 100%;
        height: 300px;
        box-sizing: border-box;
        background-color: rgb(234, 234, 234);
    }
    .msgBox{
        width: 100%;
        height: 100px;
        background-color: rgb(234, 234, 234);
        padding:10px 20px;
        box-sizing: border-box;
    }
}
:deep(.el-carousel__container){
    height:50px;
}
.pagination{
    margin-top: 5px;
    display: flex;
    justify-content: center;
}
.btnMore{
    width: 100%;
    margin-top: 20px;
    text-align: center;
}
</style>